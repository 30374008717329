import React from 'react';
import axios from 'axios';
import { Card, Button,Form,Avatar,Spin,Image,Modal,Input,message,Select,Checkbox,Switch } from 'antd';
import CompanyProfileForm from '../components/CompanyProfileForm'
import { UserOutlined } from '@ant-design/icons';
import { PlusOutlined,LoadingOutlined } from '@ant-design/icons';
import ImgsViewer from 'react-images-viewer'
import PhoneInput from 'react-phone-number-input'
import * as serverconfig from '../../views/serverconn'
import {
    Col,
    Row,
  } from 'reactstrap';

const FormItem=Form.Item;
const { Meta } = Card;
const { Option } = Select;
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

var token= ''


class ApplicationDetail extends React.Component{

    state={
        companyname:'',  
        companyemail:'',
        companylogo:null,   
        companyphone:'',
        boxnumber:'',
        datarequested:true,  
        profile:{},
        smsCost:0,
        mobilemoneypercentagecharge:0,
        smsbalance:0,
        mobilemoneygatewayurl:''
    }


    componentDidMount(){
        if(localStorage.getItem("token")){
          token= localStorage.getItem("token")
      }else{
          token= ''
      }

         axios.defaults.headers={
            "Content-Type":"application/json",
            Authorization:`Token ${token}`
          }

        axios.get(`${serverconfig.backendserverurl}/api/companyprofile/${1}`)
        .then(res => {  
            this.setState({
                profile: res.data
            })

            this.setState({companyname:res.data.company_name})
            this.setState({companyemail:res.data.company_email})
            this.setState({companyphone:res.data.RegisteredPhoneNumber1})
            this.setState({boxnumber:res.data.box_number})
            this.setState({smsCost:res.data.smsCost})
            this.setState({mobilemoneypercentagecharge:res.data.mobilemoneypercentagecharge})
            this.setState({smsbalance:res.data.smsbalance})
            this.setState({mobilemoneygatewayurl:res.data.mobilemoneygatewayurl})

          
            this.setState({datarequested:false})
        })

     

    }


    handleCompanyNameChange= (e) => this.setState({ companyname: e.target.value});
    handleCompanyEmailChange= (e) => this.setState({ companyemail: e.target.value});
    handleCompanyLogoChange= (e) => this.setState({ companylogo: e.target.files[0]});
  
    handleCompanyPhoneChange= (e) => this.setState({ companyphone: e});
    handleCompanyBoxNumberChange= (e) => this.setState({ boxnumber: e.target.value});
  
    handlesmsCostChange= (e) => this.setState({ smsCost: e.target.value});
    handlemobilemoneypercentagechargeChange= (e) => this.setState({ mobilemoneypercentagecharge: e.target.value});
    handlesmsbalanceChange= (e) => this.setState({ smsbalance: e.target.value});

    handlemobilemoneygatewayurlChange= (e) => this.setState({ mobilemoneygatewayurl: e.target.value});


    
    //submit button pressed
    handleFormSubmit=() =>{
        const profileid=1;

        this.setState({datarequested:true})

        let form_data = new FormData();
        form_data.append('company_name', this.state.companyname);
        form_data.append('company_email', this.state.companyemail);
        form_data.append('RegisteredPhoneNumber1', this.state.companyphone);
        form_data.append('box_number', this.state.boxnumber);
        form_data.append('smsCost', this.state.smsCost);
        form_data.append('mobilemoneypercentagecharge', this.state.mobilemoneypercentagecharge);
        form_data.append('smsbalance', this.state.smsbalance);
        form_data.append('mobilemoneygatewayurl', this.state.mobilemoneygatewayurl);

        this.state.companylogo==null?
    
        console.log("No profile file")
        :
        form_data.append('company_logo', this.state.companylogo,this.state.companylogo?this.state.companylogo.name:"");
        

          axios.put(serverconfig.backendserverurl+`/api/companyprofile/${profileid}/`, form_data, {
            headers: {
              'content-type': 'multipart/form-data'
            }
          })
        .then(res =>        this.setState({datarequested:false})
        , window.location.reload(false),message.info('Successfully updated application profile')
        )
        .catch(error => console.log(error))

    }


    render(){
        if(this.state.datarequested===true){
            return(
              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin indicator={antIcon} />
              </div>
            )
          }else{
            return(
                <div>
                    <Row>
                    <Col xs="12" sm="6" lg="6">
                    <Card title={this.state.profile.company_name}>
                        <p>Email: {this.state.profile.company_email}</p>
                        <p>Phone: {this.state.profile.RegisteredPhoneNumber1}</p>
                        <p>Box No: {this.state.profile.box_number}</p>
                        <p>SMS cost: {this.state.profile.smsCost}</p>
                        <p>SMS Balance: {this.state.profile.smsbalance}</p>
                        <p>Mobile Money Gate way Url: {this.state.profile.mobilemoneygatewayurl}</p>
                    
                    </Card>
                    </Col>
    
                    <Col xs="12" sm="6" lg="6">
                      <Card 
                            style={{padding:10,alignContent:'center',alignItems:'center'}}
                            hoverable
                            cover={
                            
                                <Image
                                width={100}
                                //height={'50%'}
                                src={this.state.profile.company_logo}    
                                />                   
                             }
                        >
                    </Card>


                   
                    </Col>
                   
    
                    </Row>
                    <br/>
    
                    <Row>
                    <Col xs="12" sm="6" lg="6">
                    <Card 
                            style={{padding:10}}
                            hoverable>
    
                        <h4>Sacco Profile Update</h4>
                        <Form
                            >
                                <FormItem label="Application Name"
                   
                                >
                                <Input name="applicationname"  placeholder="Put a name here." value={this.state.companyname} onChange={this.handleCompanyNameChange} />
                                </FormItem>
                    
                    
                                <FormItem label="Logo">
                                <Input name="companylogo" type="file" accept="image/png, image/jpeg" placeholder="Company logo"  onChange={this.handleCompanyLogoChange}/>
                                </FormItem>  
                                
                                <FormItem label="Email"
                 
                                >
                                <Input name="companyemail" type="email"  placeholder="company@email.com" value={this.state.companyemail} onChange={this.handleCompanyEmailChange} />
                                </FormItem> 
                    
                                <FormItem label="Phone Number">
                                <PhoneInput
                                    countrySelectProps={{ unicodeFlags: true }}
                                    defaultCountry="UG"
                                    placeholder="Enter phone number"
                                    value={this.state.companyphone} onChange={this.handleCompanyPhoneChange}/>
                                </FormItem>
                    
                                <FormItem label="Box number">
                                <Input name="boxnumber"  placeholder="Box number here" value={this.state.boxnumber} onChange={this.handleCompanyBoxNumberChange} />
                                </FormItem>

                                <FormItem label="SMS Cost">
                                <Input name="smscost"  placeholder="SMS Cost" value={this.state.smsCost} onChange={this.handlesmsCostChange} />
                                </FormItem>
                    
                                <FormItem label="Mobile Money Percentage charge">
                                <Input  placeholder="percentage eg (2)" value={this.state.mobilemoneypercentagecharge} onChange={this.handlemobilemoneypercentagechargeChange} />
                                </FormItem>      

                                <FormItem label="SMS Balance">
                                <Input name="smsbalance"  placeholder="SMS Balance" value={this.state.smsbalance} onChange={this.handlesmsbalanceChange} />
                                </FormItem>

                                <FormItem label="Mobile Money Gateway Url">
                                <Input name="url"  placeholder="Url" value={this.state.mobilemoneygatewayurl} onChange={this.handlemobilemoneygatewayurlChange} />
                                </FormItem>

                            <FormItem>
                            <Button type="primary" htmlType="button" onClick={(event) => this.handleFormSubmit()}>Update</Button>
                            </FormItem>
                            </Form>
                    </Card>
                       
    
                    </Col>
                
                    </Row>
    
                    <br/>
    
                </div>
            )

          }
     
    }
}

export default ApplicationDetail; 