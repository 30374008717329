import React from 'react';
import { Route } from 'react-router-dom';
import AdminIndex from './SiteAdmin/containers/adminindex'
import SaccosList from './SiteAdmin/containers/saccoslist'
import SaccoDetail from './SiteAdmin/containers/saccoDetailView'
import SaccoUserList from './SiteAdmin/containers/saccousers'
import SaccoUserAccountDetail from './SiteAdmin/containers/saccouseraccountdetailview'
import Saccowalletstatementreport from './SiteAdmin/containers/saccowalletstatementreport'
import SaccoSmsUsagestatementreport from './SiteAdmin/containers/saccosmsusagestatementreport'
import PackageDetail from './SiteAdmin/containers/PackageDetailView'
import ApplicationDetail from './SiteAdmin/containers/ApplicationDetailView'

import PackageList from './SiteAdmin/containers/packageslist'

const AdminBaseRouter = () =>(
    <div>
        <Route exact path='/admin/' component={AdminIndex} />
        <Route exact path='/' component={AdminIndex}  />
        <Route exact path='/saccos/' component={SaccosList}  />
        <Route exact path='/saccos/:saccoId' component={SaccoDetail}  />
        <Route exact path='/saccousers/:saccoId' component={SaccoUserList}  />
        <Route exact path='/saccouserdetails/:userid' component={SaccoUserAccountDetail}  />
        <Route exact path='/vendorwalletstatement/' component={Saccowalletstatementreport}  />
        <Route exact path='/smsusagestatement/' component={SaccoSmsUsagestatementreport}  />
        <Route exact path='/packages/' component={PackageList}  />
        <Route exact path='/packages/:packageId' component={PackageDetail}  />
        <Route exact path='/applicationdetails/' component={ApplicationDetail}  />
    </div>

);

export default AdminBaseRouter;


